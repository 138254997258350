import React from "react"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import List, { ListItem } from "@kiwicom/orbit-components/lib/List"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import {
  DriverNumber,
  HubSupportNumber,
} from "components/generic/phone-numbers"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Company Internal Chat Policy">
    <Hero title="Company Internal Chat Guidelines" />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Stack>
            <Heading type="title3">General Guidance</Heading>
            <Text>
              All company chat should be respectful, polite and on topic. This
              doesn’t mean you can’t disagree or provide negative views. We
              welcome those in a polite way and want to constructively engage
              with it. However, any deviation from this or any personal abuse
              will be considered as a breach of company guidelines and may
              result in suspension from duties or a formal warning. Everyone at
              Ember is working towards the same goal and any personal issues
              should be raised with your line manager in a civil manner.
            </Text>
            <Text>
              The text below provides some guidance on how to use the different
              rooms on Google Chat.
            </Text>
            <Heading type="title3">Driver Announcements</Heading>
            <Text>
              {" "}
              This room is used to post important updates which affect many or
              all drivers. Only authorised people and/or teams should post
              messages in this room. This may include information on topics such
              as:
            </Text>
            <List>
              <ListItem>Road Closures</ListItem>
              <ListItem>Diversions</ListItem>
              <ListItem>Other Critical Updates</ListItem>
              <ListItem>Policy Changes</ListItem>
              <ListItem>Team News and Updates</ListItem>
              <ListItem>Tech Updates</ListItem>
            </List>
            <Text>
              You should not comment on posts in driver announcements to avoid
              clutter as it risks others missing important information.
            </Text>
            <Text>
              You’re always welcome to comment and ask questions about the
              announcement in your own room. If it’s something to the benefit of
              the whole team then Ops will add that information back to the
              announcement.
            </Text>
            <Heading type="title3">Charger Alerts</Heading>
            <Text>
              If a coach stops charging at any point before it has enough charge
              for its next scheduled trip, an alert is automatically posted. A
              driver is usually best placed to resolve this but there are
              situations where someone else may be better placed - such as
              in-between shifts. The primary thing is to respond to say you are
              going to try and resolve it so that someone else doesn’t also
              attend.
            </Text>
            <Text>
              If the driver is responding to the charger alert then this does
              count as work and would require you to reset your rest period.
              Taking a legal break is the main priority but this can be split
              and if necessary it can simply be restarted.
            </Text>
            <Text>
              If you are carrying out work during your mid shift break time then
              you should add these hours via the Extra Hours form on driver hub
              so that your actual hours worked are correctly recorded in the
              system.
            </Text>
            <Text>
              As a reminder, all drivers need to ensure you have had either of
              the following between trips:
            </Text>
            <List>
              <ListItem>A 45 minute uninterrupted rest period or</ListItem>
              <ListItem>
                A 15 minute uninterrupted rest period followed by a further 30
                min uninterrupted rest period
              </ListItem>
            </List>
            <Heading type="title3">Driver Training</Heading>
            <Text>
              This room is used to communicate small updates on training as well
              as a space for drivers to ask questions on how something should be
              done.
            </Text>
            <Text>
              The idea here is that any questions asked will only be responded
              to by a designated trainer or by management. This keeps any and
              all responses to training questions consistent.
            </Text>
            <Text>
              Please do not randomly chat in this space. As with driver
              announcements we don’t want the room clogged with unnecessary
              noise.
            </Text>
            <Heading type="title3">Your Own Room</Heading>
            <Text>
              This is a space for yourself and the Operations Team to
              communicate with each other. Anything said in this room would be
              treated with strict confidence and go no further than absolutely
              necessary.
            </Text>
            <Text>
              There is always an on-duty member of Ops between 09:00-18:00 every
              day, who will respond to any messages in your room as quickly as
              they can. If something requires an urgent response then it's best
              to call the driver line.
            </Text>
            <Text>
              Outside of those hours, you can still post non-urgent messages for
              us to pick up the following morning. If there is anything urgent
              outside these hours, please call the Driver Line on{" "}
              <DriverNumber />. This number is exclusively for driver use and
              will redirect itself to the on-call Ops Manager that night. If you
              need to reach hub support for a local issue (such as screenwash)
              then you can call them on <HubSupportNumber />.
            </Text>
            <Text>
              Generally speaking, anything urgent would be something that will
              impact your shift, such as your coach has failed its safety check,
              or you can’t make it in for your shift. For things like delays, we
              have automated systems that alert passengers who are booked on
              delayed services so no need to call it in outside of hours unless
              it is an exceptional situation - such as a 1hr delay.
            </Text>
          </Stack>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
