// @ts-strict-ignore
import React from "react"

import { TextLink } from "@kiwicom/orbit-components"

const PhoneNumber = ({
  machine,
  human,
}: {
  machine: string
  human: string
}) => (
  // NB we deliberately don't accept custom text for the contents of the link here. The idea is that
  // the number is both readable on screen, and clickable on mobile.
  <TextLink href={`tel:${machine}`}>{human.replace(" ", "\u00A0")}</TextLink>
)

export const DriverNumber = () => (
  <PhoneNumber
    machine={process.env.DRIVER_NUMBER_MACHINE_READABLE}
    human={process.env.DRIVER_NUMBER_HUMAN_READABLE}
  />
)

export const HubSupportNumber = () => (
  <PhoneNumber
    machine={process.env.HUB_SUPPORT_NUMBER_MACHINE_READABLE}
    human={process.env.HUB_SUPPORT_NUMBER_HUMAN_READABLE}
  />
)
